<template>
  <b-sidebar
    id="add-new-poi-form"
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ poiData.id ? 'Update' : 'Add New' }} Point of Interest
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <validation-observer ref="newPoiForm">
        <b-form
          class="p-2"
          @submit.prevent="validateForm()"
        >
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="first-name"
            >
              <b-form-input
                id="name"
                v-model="poiData.name"
                :state="errors.length > 0 ? false : null"
                name="name"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Is Gib Entry or Exist"
            rules="required"
          >
            <b-form-group
              label="Is Gib Entry or Exist"
              label-for="is-entry-exist"
            >
              <v-select
                v-model="poiData.isGibEntryOrExitPoint"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                label="label"
                :reduce="(options) => options.value"
                input-id="is-entry-exist"
              >
                <template #option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="GPS Long"
            label-for="long"
          >
            <b-form-input
              id="long"
              v-model="poiData.gpslong"
              name="long"
              type="number"
              step=".000001"
            />
          </b-form-group>

          <b-form-group
            label="GPS Lat"
            label-for="lat"
          >
            <b-form-input
              id="lat"
              v-model="poiData.gpslat"
              name="lat"
              type="number"
              step=".000001"
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="Is GHA"
            rules="required"
          >
            <b-form-group
              label="Is GHA"
              label-for="is-gha"
            >
              <v-select
                v-model="poiData.isGha"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                label="label"
                :reduce="(options) => options.value"
                input-id="is-gha"
              >
                <template #option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              {{ poiData.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="
                resetForm();
                hide();
              "
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BSidebar,
  BSpinner,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import usePoi from '@/modules/poi/composables/usePoi';

export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    poi: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const toast = useToast();
    const { createPoi, editPoi } = usePoi();

    const poiData = ref(props.poi);
    const newPoiForm = ref(null);
    const isLoading = ref(false);

    const resetForm = () => {
      newPoiForm.value.reset();
    };

    const validateForm = async () => {
      if (isLoading.value) return;

      isLoading.value = true;

      const valid = await newPoiForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      try {
        poiData.value.id
          ? await editPoi(poiData.value)
          : await createPoi(poiData.value);

        ctx.emit('form-submitted');
      } catch (error) {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'An error occurred.',
            icon: 'XCircleIcon',
          },
        });
      }

      isLoading.value = false;
    };

    return {
      // Reacitve
      newPoiForm,
      poiData,
      isLoading,

      // Method
      resetForm,
      validateForm,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-poi-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
