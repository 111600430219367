import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';

const usePoiList = () => {
  const toast = useToast();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'isGibEntryOrExitPoint' },
    { key: 'isGha', label: 'Is GHA' },
    { key: 'actions' },
  ];

  const pageNumber = ref(1);
  const orderDesc = ref(true);
  const pageSize = ref(10);
  const refPoiTable = ref(null);
  const searchQuery = ref(null);
  const orderBy = ref('id');
  const totalPoi = ref(0);

  const dataMeta = computed(() => {
    const localItemsCount = refPoiTable.value
      ? refPoiTable.value.localItems.length
      : 0;

    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalPoi.value,
    };
  });

  watch([pageNumber, pageSize], () => {
    refPoiTable.value.refresh();
  });

  const getPoiList = (_, callback) => {
    const params = {
      orderBy: orderBy.value,
      orderDesc: orderDesc.value,
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
      searchQuery: searchQuery.value,
    };

    store
      .dispatch('poi/getList', params)
      .then(({ poi, total }) => {
        callback(poi);
        totalPoi.value = total;
      })
      .catch(() => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Error fetching poi list',
            icon: 'XCircleIcon',
          },
        });
      });
  };

  return {
    // Const
    perPageOptions,
    tableColumns,

    // Reactive
    pageNumber,
    orderDesc,
    pageSize,
    refPoiTable,
    searchQuery,
    orderBy,
    totalPoi,

    // Computed
    dataMeta,

    // Method
    getPoiList,
  };
};

export default usePoiList;
