<template>
  <div>
    <poi-form
      v-if="isPoiFormActive"
      :is-active.sync="isPoiFormActive"
      :poi="poi"
      @form-submitted="formSubmitted"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>

            <v-select
              v-model="pageSize"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Press ENTER to search"
                @keyup.enter="refPoiTable.refresh();"
              />

              <b-button
                variant="primary"
                @click="openForm"
              >
                <span class="text-nowrap">Add Point</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="poi-table"
        ref="refPoiTable"
        class="position-relative"
        :items="getPoiList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="orderDesc"
        style="min-height:110px"
      >
        <template #cell(name)="data">
          <p class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </p>
        </template>

        <template #cell(isGibEntryOrExitPoint)="data">
          <b-badge
            pill
            :variant="(data.item.isGibEntryOrExitPoint) ? 'light-success' : 'light-secondary'"
          >
            {{ (data.item.isGibEntryOrExitPoint) ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <template #cell(isGha)="data">
          <b-badge
            pill
            :variant="(data.item.isGha) ? 'light-success' : 'light-secondary'"
          >
            {{ (data.item.isGha) ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editPoi(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalPoi"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BPagination, BRow, BTable } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import Swal from 'sweetalert2';
import usePoiList from '@/modules/poi/composables/usePoiList';
import PoiForm from '@/modules/poi/components/PoiForm.vue';
import usePoi from '@/modules/poi/composables/usePoi';

export default {
  setup() {
    const { deletePoi } = usePoi();
    const {
      dataMeta,
      getPoiList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refPoiTable,
      searchQuery,
      tableColumns,
      totalPoi,
    } = usePoiList();

    const emptyPoi = {
      gpsLat: null,
      gpsLong: null,
      id: null,
      isGha: null,
      isGibEntryOrExitPoint: null,
      name: null,
    };
    const isPoiFormActive = ref(false);
    const poi = ref({ ...emptyPoi });

    const editPoi = (poiSelected) => {
      poi.value = { ...poiSelected };
      isPoiFormActive.value = true;
    };

    const formSubmitted = () => {
      isPoiFormActive.value = false;
      poi.value = { ...emptyPoi };
      refPoiTable.value.refresh();
    };

    const openForm = () => {
      poi.value = { ...emptyPoi };
      isPoiFormActive.value = true;
    };

    const removePoi = async (poi) => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await deletePoi(poi);

      refPoiTable.value.refresh();
    };

    return {
      //Reactive
      isPoiFormActive,
      poi,

      // Methods
      editPoi,
      formSubmitted,
      openForm,
      removePoi,

      // UseTaxiList
      dataMeta,
      getPoiList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refPoiTable,
      searchQuery,
      tableColumns,
      totalPoi,
    };
  },
  components: {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    PoiForm,
    vSelect,
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

#poi-table {
  min-height: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>