import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const usePoi = () => {
  const toast = useToast();

  const createPoi = async (poi) => {
    try {
      return await store.dispatch('poi/create', poi);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating point of interest',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const deletePoi = async (poi) => {
    try {
      await store.dispatch('poi/remove', poi);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting point of interest',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const editPoi = async (poi) => {
    try {
      return await store.dispatch('poi/update', poi);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating point of interest',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getList = async (filter = {}) => {
    try {
      return await store.dispatch('poi/getList', filter);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the point of interest list',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    createPoi,
    deletePoi,
    editPoi,
    getList,
  };
};

export default usePoi;
