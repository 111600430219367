var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-poi-form","visible":_vm.isActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.poiData.id ? 'Update' : 'Add New')+" Point of Interest ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.resetForm();
          hide();}}})],1),_c('validation-observer',{ref:"newPoiForm"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"first-name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name","placeholder":"Name"},model:{value:(_vm.poiData.name),callback:function ($$v) {_vm.$set(_vm.poiData, "name", $$v)},expression:"poiData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Is Gib Entry or Exist","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is Gib Entry or Exist","label-for":"is-entry-exist"}},[_c('v-select',{attrs:{"options":[
                { label: 'Yes', value: true },
                { label: 'No', value: false } ],"label":"label","reduce":function (options) { return options.value; },"input-id":"is-entry-exist"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.poiData.isGibEntryOrExitPoint),callback:function ($$v) {_vm.$set(_vm.poiData, "isGibEntryOrExitPoint", $$v)},expression:"poiData.isGibEntryOrExitPoint"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"GPS Long","label-for":"long"}},[_c('b-form-input',{attrs:{"id":"long","name":"long","type":"number","step":".000001"},model:{value:(_vm.poiData.gpslong),callback:function ($$v) {_vm.$set(_vm.poiData, "gpslong", $$v)},expression:"poiData.gpslong"}})],1),_c('b-form-group',{attrs:{"label":"GPS Lat","label-for":"lat"}},[_c('b-form-input',{attrs:{"id":"lat","name":"lat","type":"number","step":".000001"},model:{value:(_vm.poiData.gpslat),callback:function ($$v) {_vm.$set(_vm.poiData, "gpslat", $$v)},expression:"poiData.gpslat"}})],1),_c('validation-provider',{attrs:{"name":"Is GHA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is GHA","label-for":"is-gha"}},[_c('v-select',{attrs:{"options":[
                { label: 'Yes', value: true },
                { label: 'No', value: false } ],"label":"label","reduce":function (options) { return options.value; },"input-id":"is-gha"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.poiData.isGha),callback:function ($$v) {_vm.$set(_vm.poiData, "isGha", $$v)},expression:"poiData.isGha"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.poiData.id ? 'Update' : 'Add')+" ")],1),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.resetForm();
              hide();}}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }